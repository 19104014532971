import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <SEO title="Oops, Maaf!" />
      <Layout>
        <h1>Maaf, halaman yang kamu cari tidak dapat ditemukan.</h1>
        <p>
          Apakah boleh kami meminta tolong kamu untuk mengirim ke kami link yang
          kamu cari?
        </p>
        <input className="form-control" type="text" />
      </Layout>
    </React.Fragment>
  )
}
export default NotFoundPage
